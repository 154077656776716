// local
// const BASE_URL = "http://localhost:3000";

// prod
const BASE_URL = "https://onboardingapi.voosh.ai";

export const urls = {
  signIn: `${BASE_URL}/v1/auth/signin`,
  refreshToken: `${BASE_URL}/v1/auth/refresh`,
  getOnboardinglist: `${BASE_URL}/v1/client/fetch-onboarded-clients`,
  checkChannelStatus: `${BASE_URL}/channelstatus`,
  createOnboarding: `${BASE_URL}/v1/client/onboard`,
};
