import React from "react";
import "./splashScreen.scss";

import { Modal } from "@mui/material";
import { icons } from "../../global/images/icons";

function SplashScreen({ showSplash }) {
  return (
    <Modal
      open={showSplash}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-container"
    >
      <div className="splash-container">
        <img src={icons.splash_gif} className="splash-gif" />
        <img src={icons.vooshLogoSquare} className="splash-voosh-logo" />
      </div>
    </Modal>
  );
}

export default SplashScreen;
