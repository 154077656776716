import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { ToastContainer } from "react-toastify";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "./global/key";
import BoardingList from "./pages/boardingList/boardingList";
import Login from "./pages/login/login";
import SplashScreen from "./pages/splashScreen/splashScreen";
import { getAccessToken } from "./services/apis";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) {
      setLoggedIn(false);
      setLoading(false);
    } else {
      getAccessToken()
        .then((res) => {
          sessionStorage.setItem(ACCESS_TOKEN, res.data.data.token);
          setLoggedIn(true);
        })
        .catch((err) => {
          setLoggedIn(false);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className="app-container">
      {loggedIn ? (
        <BoardingList setLoggedIn={setLoggedIn} />
      ) : (
        <Login setLoggedIn={setLoggedIn} />
      )}
      <ToastContainer autoClose={3000} />
      <SplashScreen showSplash={loading} />
    </div>
  );
}

export default App;
