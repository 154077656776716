import React, { useState } from "react";
import "./login.scss";

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { icons } from "../../global/images/icons";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../global/key";
import { signIn } from "../../services/apis";

function Login({ setLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberme, setRememberme] = useState(true);
  const [currentScreen, setCurrentScreen] = useState("LOGIN_SCREEN");
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      return;
    }
    setLoading(true);
    signIn({ email, password })
      .then((res) => {
        setLoggedIn(true);
        if (rememberme) {
          localStorage.setItem(REFRESH_TOKEN, res.data.data.refresh);
        }
        sessionStorage.setItem(ACCESS_TOKEN, res.data.data.token);
      })
      .catch((err) => {
        setCurrentScreen("ERROR_SCREEN");
        console.log("error in signIn", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-main">
      <img
        src={icons.vooshLogoSquare}
        className="voosh-logo"
        alt="voosh-logo"
      />
      {currentScreen === "LOGIN_SCREEN" && (
        <div className="login-container">
          <div className="login_title_container">
            <div className="login_title">Login</div>
          </div>

          <form className="login-form-container" onSubmit={handleLogin}>
            <TextField
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form_input"
              required
              type="email"
            />
            <div>
              <TextField
                label="Password"
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form_input"
                required
                type={showPassword ? "text" : "password"}
              />
              <button className="password-eye-button" type="button">
                {showPassword ? (
                  <AiFillEye onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <AiFillEyeInvisible
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </button>
            </div>

            <div className="checkbox-container">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberme}
                    onChange={(e) => {
                      setRememberme(e.target.checked);
                    }}
                  />
                }
                label="Remember me"
              />
            </div>

            <button className="login_button" disabled={loading} type="submit">
              <span className="login_button_text">Login</span>
              {loading && (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              )}
            </button>
          </form>
        </div>
      )}
      {currentScreen === "ERROR_SCREEN" && (
        <div className="login_error_container">
          <img
            src={icons.login_error}
            className="login_error_img"
            alt="login_error_img"
          />
          <div className="login_error_text">
            Unable to login/sign-up please try again
          </div>
          <button
            className="login_error_button"
            onClick={() => {
              setCurrentScreen("LOGIN_SCREEN");
              setEmail("");
              setPassword("");
            }}
          >
            <BiRefresh color="#ffffff" />
            <span>Refresh</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Login;
