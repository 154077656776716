import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../global/key";
import { urls } from "./urls";

export const signIn = ({ email, password }) => {
  let data = JSON.stringify({
    email,
    password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: urls.signIn,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios.request(config);
};

export const getAccessToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  let data = JSON.stringify({
    refresh: refreshToken,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: urls.refreshToken,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios.request(config);
};

export const getListofBoarding = () => {
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: urls.getOnboardinglist,
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };

  return axios.request(config);
};

export const checkChannelStatus = ({ channels }) => {
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  const data = JSON.stringify({
    channels,
  });
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: urls.checkChannelStatus,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
    data: data,
  };

  return axios.request(config);
};

export const createOnboarding = ({
  generated_email,
  client_name,
  communication_mail,
  channel,
}) => {
  const access_token = sessionStorage.getItem(ACCESS_TOKEN);
  let data = JSON.stringify({
    generated_email,
    client_name,
    communication_mail,
    channel: channel.map((item) => {
      return {
        name: item.name,
        status: item.checked,
      };
    }),
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: urls.createOnboarding,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    },
    data: data,
  };

  return axios.request(config);
};
