export const icons = {
  vooshLogoSquare: require("./vooshLogoSquare.png"),
  login_error: require("./login_error.webp"),
  chip_round_logo_doordash_drive: require("./chip_round_logo_doordash_drive.webp"),
  chip_round_logo_doordash: require("./chip_round_logo_doordash.webp"),
  chip_round_logo_google: require("./chip_round_logo_google.webp"),
  chip_round_logo_yelp: require("./chip_round_logo_yelp.webp"),
  chip_round_logo_ubereats: require("./chip_round_logo_ubereats.webp"),
  chip_round_logo_grubhub: require("./chip_round_logo_grubhub.webp"),
  chip_round_logo_ezcater: require("./chip_round_logo_ezcater.webp"),
  chip_round_logo_std: require("./chip_round_logo_std.webp"),
  splash_gif: require("./splash_screen.gif"),
};
