import React, { useState } from "react";
import "./newOnboarding.scss";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { icons } from "../../global/images/icons";
import { createOnboarding } from "../../services/apis";

const channel_info = [
  {
    name: "Doordash",
    icon: icons.chip_round_logo_doordash,
    value: "DOORDASH",
    checked: true,
  },
  {
    name: "Ubereats",
    icon: icons.chip_round_logo_ubereats,
    value: "UBEREATS",
    checked: true,
  },
  {
    name: "Grubhub",
    icon: icons.chip_round_logo_grubhub,
    value: "GRUBHUB",
    checked: true,
  },
  {
    name: "Google",
    icon: icons.chip_round_logo_google,
    value: "GOOGLE",
    checked: true,
  },
  {
    name: "Yelp",
    icon: icons.chip_round_logo_yelp,
    value: "YELP",
    checked: true,
  },
  {
    name: "Ezcater",
    icon: icons.chip_round_logo_ezcater,
    value: "EZCATER",
    checked: true,
  },
  {
    name: "Doordash Drive",
    icon: icons.chip_round_logo_doordash_drive,
    value: "DOORDASH_DRIVE",
    checked: true,
  },
  {
    name: "Skip the dishes",
    icon: icons.chip_round_logo_std,
    value: "SKIP_THE_DISHES",
    checked: true,
  },
];

function NewOnboarding({ show, onDismiss, callback }) {
  const [onboardingMail, setOnboardingMail] = useState("");
  const [onboardingName, setOnboardingName] = useState("");
  const [communicationMail, setCommunicationMail] = useState("");
  const [selectedChannels, setSelectedChannels] = useState(channel_info);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (onboardingMail.trim().length === 0) {
      toast.error("Onboarding Id is required");
      return;
    }
    if (onboardingName.trim().length === 0) {
      toast.error("Onboarding Name is required");
      return;
    }
    if (communicationMail.trim().length === 0) {
      toast.error("Communication Mail is required");
      return;
    }
    if (selectedChannels.filter((item) => item.checked).length === 0) {
      toast.error("Select atleast one channel");
      return;
    }
    setLoading(true);
    createOnboarding({
      generated_email: "restaurantshub+" + onboardingMail + "@voosh.in",
      client_name: onboardingName,
      communication_mail: communicationMail,
      channel: selectedChannels,
    })
      .then((res) => {
        setLoading(false);
        callback();
        toast.success("Onboarding created successfully");
        onDismiss();
      })
      .catch((err) => {
        console.log("error in create onboarding", err);
        toast.error("Error in creating onboarding");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      open={show}
      onClose={onDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-container"
    >
      <Box className="modal-inner-container">
        <div className="new-onboarding-modal-div">
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div className="new-onboarding-modal-title">New Onboarding</div>
            <div className="divider" />
            <div className="title">Enter onboarding info</div>
            <div className="new-onboarding-modal-form">
              <div className="onboarding-input">
                <div className="pre-text">restaurantshub+</div>
                <TextField
                  label="Onboarding Id"
                  variant="standard"
                  className="form_input"
                  required
                  name="onboardingId"
                  value={onboardingMail}
                  onChange={(e) => setOnboardingMail(e.target.value)}
                />
                <div className="pre-text">@voosh.in</div>
              </div>
              <TextField
                label="Onboarding Name"
                variant="standard"
                className="form_input"
                required
                value={onboardingName}
                name="onboardingName"
                onChange={(e) => setOnboardingName(e.target.value)}
              />
              <TextField
                label="Communication Mail"
                variant="standard"
                className="form_input"
                required
                type="email"
                name="communicationMail"
                value={communicationMail}
                onChange={(e) => setCommunicationMail(e.target.value)}
              />
            </div>
            <div className="divider" />
            <div className="title">Mail will be sent for these channels</div>
            <div className="channel-container">
              {selectedChannels.map((item, index) => (
                <div className="channel-chip" key={index}>
                  <Checkbox
                    checked={item.checked}
                    onChange={() => {
                      const newChannels = structuredClone(selectedChannels);
                      newChannels[index].checked = !newChannels[index].checked;
                      setSelectedChannels(newChannels);
                    }}
                  />
                  <img src={item.icon} alt={item.name} className="logo" />
                  <div className="name"> {item.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="divider" />
          <div className="button-container">
            <Button
              variant="outlined"
              className="cancel_button"
              onClick={onDismiss}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="submit_button"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? <CircularProgress size={15} /> : "Submit"}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default NewOnboarding;
