import React, { useEffect, useState } from "react";
import "./boardingList.scss";

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { icons } from "../../global/images/icons";
import { getListofBoarding } from "../../services/apis";
import NewOnboarding from "../newOnBoarding/newOnboarding";
const DASHBOARD_ACCESS_COLORS = {
  ACCESS_REQUESTED: "#FFC000",
  ACCESS_VERIFIED: "#7FFFD4",
  ACCESS_DENIED: "#f88379",
  ONBOARDING_NOT_STARTED: "#F3CFC6",
  ONBOARDING_IN_PROGRESS: "#B9D9EB",
  ONBOARDING_COMPLETE: "#CCCCFF",
};

function BoardingList({ setLoggedIn }) {
  const [search, setSearch] = useState("");
  const [showNewOnboarding, setShowNewOnboarding] = useState(false);
  const [seearchedData, setSearchedData] = useState([]);

  function toSpaceSeparatedPascalCase(str) {
    return str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const fetchData = () => {
    getListofBoarding()
      .then((res) => {
        setSearchedData(res.data.data);
      })
      .catch(() => {
        console.log("error");
        toast.error("Error in fetching data");
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="boarding-list-container">
      <div className="header-container">
        <img
          src={icons.vooshLogoSquare}
          className="voosh-logo"
          alt="voosh-logo"
        />
        <button
          className="logout"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            setLoggedIn(false);
          }}
        >
          Logout
        </button>
      </div>
      <div className="action-container">
        <TextField
          label="Search Onboarding Mail"
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className="search"
        />
        <Button
          variant="contained"
          className="new_button"
          onClick={() => {
            setShowNewOnboarding(true);
          }}
        >
          New Onboarding
        </Button>
      </div>
      <div className="table-container">
        <TableContainer
          component={Paper}
          sx={{ overflowY: "scroll", height: "75vh" }}
        >
          <Table>
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                zIndex: "1",
                background: "#fff",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr.</TableCell>
                <TableCell align="center">Onboarding Mail</TableCell>
                <TableCell align="center">Onboarding Name</TableCell>
                <TableCell align="center">Communication Mail</TableCell>
                {(seearchedData?.[0]?.dashboard_access ?? []).map(
                  (item, index) => (
                    <TableCell align="center" key={index}>
                      {item.name}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {seearchedData.filter((item) =>
                item.generated_email
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ).length === 0 && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={11}
                    sx={{ textAlign: "center", height: "200px" }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              )}
              {seearchedData
                .filter((item) =>
                  item.generated_email
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((row, ind) => (
                  <TableRow key={ind}>
                    <TableCell align="center">{ind + 1}.</TableCell>
                    <TableCell align="center">{row.generated_email}</TableCell>
                    <TableCell align="center">{row.client_name}</TableCell>
                    <TableCell align="center">{row.client_email}</TableCell>
                    {(row?.dashboard_access ?? []).map((item1, index1) => (
                      <TableCell align="center" key={index1}>
                        <span
                          className="chip-else"
                          style={{
                            backgroundColor:
                              DASHBOARD_ACCESS_COLORS[item1.status],
                          }}
                        >
                          {toSpaceSeparatedPascalCase(item1.status)}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showNewOnboarding && (
        <NewOnboarding
          show={showNewOnboarding}
          onDismiss={() => {
            setShowNewOnboarding(false);
          }}
          callback={fetchData}
        />
      )}
    </div>
  );
}

export default BoardingList;
